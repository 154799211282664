.mt-300 {
    transition: all 300ms;
    margin-top: 200px;
}

#kt_login_signin_form {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
}

#registration_form {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    height: 100%;
    width: 100%;
}

#inviteRegistration_form {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
}

.description-with-image {
    @include media-breakpoint-up(lg) {
        min-height: 900px;
    }

    &--gray {
        background-color: white;
        position: relative;
        &::before {
            position: absolute;
            content: '';
            left: 40px;
            top: 40px;
            right: 40px;
            bottom: 40px;
            background: #F1F4FA;
        }
        
    }
}

.forgotten-password-form {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

#successfull_registration_wrapper {}