// Components
@import "./core/components/components";
@import 'trainee';

.esl-accordion { // __header, __body, __item
    &__header {
        padding: 15px;
        color: white;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    &__body {
        padding: 15px;
        color: white;
        font-size: 16px;
        font-weight: 300;
        display: none;
    }

    &__item {
        margin-bottom: 10px;
        background: #FFFFFF33;
        border-radius: 10px;
        overflow: hidden;
        padding: 0;
        color: white;

        &.opened {
            .esl-accordion__body {
                display: block;
            }

            .esl-accordion__arrow {
                transform: rotate(180deg);
            }
        }
    }

    &__arrow {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        transition: transform 0.3s;

        i {
            color: white;
            font-size: 24px;
        }
    }

}

.form-control {
    &--white {
        color: white;
        border-color: white;

        &:focus {
            color: white;
        }

        &::placeholder {
            color: white;
        }
    }
}

.rotate-180 {
    transform: rotate(180deg);
}