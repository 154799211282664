@import '../sass/components/variables.custom.scss';

$lg: 992px;

.trainee-sidebar {

    padding: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: $lg) {
        padding: 20px;
    }

    &__header {
        height: 170px;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $pale-red;
            opacity: 0.8;
        }

        position: relative;
        border-radius: 10px;
        overflow: hidden;
        background-image: url('../../../../public/media/esl/auth_bg.jpg');
        background-size: cover;
        background-position: center;
        padding: 30px;
        flex-grow: 1;
        color: white;

        & > * {
            position: relative;
        }
    }

    &__info-block {
        border-radius: 10px;
        padding: 15px;
        background: #FFFFFF33;
        color: white;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
    }
}

.btn.btn-esl-red {
    background-color: white;
    color: $esl-red;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-size: 20px;
    font-weight: 300;
}

.trainee-group-list {
    margin:0;
    padding: 0;
    
    &__item {
        border-radius: 10px;
        background: white;
        color: $esl-red;
        margin-bottom: 5px;
        padding: 5px 10px;
        list-style-type: none;
    }
}

.trainee-content {
    padding: 0;
    background: white;
    display: grid;
    gap: 0;
    grid-template-rows: auto 1fr auto;
    flex-grow: 1;

    @media (min-width: $lg) {
        grid-template-rows: 1fr auto;
        padding: 20px;
        gap: 20px;
    }

    &__header {
        display: block;
        border-radius: 10px;
        background-color: $esl-red;
        color: white;
        padding: 15px;
        @media (min-width: $lg) {
            display: none;
        }

    }

    &__body {
        border-radius: 10px;
        background-color: #F1F4FA;
        padding: 15px;
        display: flex;

        @media (min-width: $lg) {
            padding: 20px;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $esl-red;
        min-height: 105px;
        border-radius: 10px;
        color: white;
        font-size: 32px;
        line-height: 37px;
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        padding: 15px;

        @media (min-width: $lg) {
            min-height: 115px;
        }
    }
}

.svg-icon--trainee-card-action {
    svg {
        width: 46px;
        height: 46px;

        @media (min-width: $lg) {
            width: 60px;
            height: 60px;
        }
    }
}

.trainee-card {
    margin: auto;
    width: 100%;
    max-width: 375px;
    min-height: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 10px 40px 0px #00000066;
    text-align: center;
    padding: 15px;

    @media (min-width: $lg) {
        min-height: 640px;
    }

    img {
        max-width: 100%;
    }

    &--no-card {
        background-color: $pale-red;
        color: white;

        h3 {
            font-size: 32px;
            font-weight: 300;
            color: white;
            text-align: center;
        }
    }

    &__header {
        &-title {
            font-size: 32px;
            text-align: center;
            text-transform: uppercase;
            color: $esl-red;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                width: 150px;
                height: 0px;
                border-bottom: 2px dotted $esl-red;
            }
        }
        margin-bottom: 30px;
    }

    &__content {
        background-color: $esl-red;
        color: white;
        border-radius: 10px;
        padding: 30px;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
    }
}